import React, { useEffect } from "react"
import { Link } from 'gatsby'

import profil from "../../static/img/profil.jpg"
import { dark, checkTheme } from "../components/main.js"

import "../../static/css/main.scss"
import "../../static/css/font-awesome.css"
import "../../static/css/animate.css"

import Helmet from 'react-helmet'

export default ({children}) => {

  useEffect(() => {
    checkTheme();
    // if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    //   document.getElementsByTagName("body")[0].classList.add('dark-body');
    //   document.getElementsByClassName("icon")[0].classList.remove('fa-moon-o');
    //   document.getElementsByClassName("icon")[0].classList.add('fa-sun-o');
    //   localStorage.setItem('theme', 'dark');
    // }

  }, []);

  return (
    <div className="container">
      <Helmet
        title="Daniel Jakobian's Blog"
        meta={[
          { name: 'description', content: `It's just this, he said, and nothing else.` },
          { name: 'keywords', content: 'Daniel Jakobian, Blog' },
        ]}
      >
        <html lang="en" />
      </Helmet>
      <div className="sidebar">
        <div className="pic">
          <Link to={`/`}>
            <h2 className="homeText animated fadeIn">GO HOME GO HOME GO HOME GO HOME GO HOME GO HOME GO HOME</h2>
            <img src={profil} className="profilpic" alt="home-button"/>
          </Link>
        </div>
        <div className="footer">
          <Link to={'/about'} title="About me" className="about"><b className="aboutText">about </b><b>me</b></Link><br/>
          <a href="https://twitter.com/danieljakobian" title="Twitter"  target="_blank"  rel="noopener noreferrer" className="twitter"><i className="fa fa-twitter"></i></a>
          <br/>
          <a href="https://superrare.co/danieljakobian/creations" title="Superrare" target="_blank" rel="noopener noreferrer" className="superrare"><i className="fa fa-diamond"></i></a>
          <br/>
          <a href="https://www.instagram.com/danieljakobian/" title="Instagram" target="_blank" rel="noopener noreferrer" className="insta"><i className="fa fa-instagram"></i></a>
          <br/>
          <a href="https://github.com/KingLouisXVII" title="Github" target="_blank" rel="noopener noreferrer" className="github"><i className="fa fa-github"></i></a>
          <br/>
          <button className="moon" title="Darkmode" onClick={event => {
            event.preventDefault()
            dark();
          }}><i className="icon fa fa-moon-o" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <div className="content">
        {children}
      </div>
    </div>
  )
}

