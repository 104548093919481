export const dark = () => {
  if ( localStorage.getItem('theme') === 'dark') {
    document.getElementsByTagName("body")[0].classList.remove('dark-body');
    document.getElementsByClassName("icon")[0].classList.add('fa-moon-o');
    document.getElementsByClassName("icon")[0].classList.remove('fa-sun-o');
    localStorage.setItem('theme', 'light');
  }
  else if (localStorage.getItem('theme') === null || undefined) {
    document.getElementsByTagName("body")[0].classList.add('dark-body');
    document.getElementsByClassName("icon")[0].classList.remove('fa-moon-o');
    document.getElementsByClassName("icon")[0].classList.add('fa-sun-o');
    localStorage.setItem('theme', 'dark');
  }
  else if (localStorage.getItem('theme') === 'light') {
    document.getElementsByTagName("body")[0].classList.add('dark-body');
    document.getElementsByClassName("icon")[0].classList.remove('fa-moon-o');
    document.getElementsByClassName("icon")[0].classList.add('fa-sun-o');
    localStorage.setItem('theme', 'dark');
  }
}

export const checkTheme = () => {
  if ( localStorage.getItem('theme') === 'dark' ) {
    document.getElementsByTagName("body")[0].classList.add('dark-body');
    document.getElementsByClassName("icon")[0].classList.remove('fa-moon-o');
    document.getElementsByClassName("icon")[0].classList.add('fa-sun-o');
  }
  if ( localStorage.getItem('theme') === 'light' ) {
    document.getElementsByTagName("body")[0].classList.remove('dark-body');
    document.getElementsByClassName("icon")[0].classList.remove('fa-moon-o');
    document.getElementsByClassName("icon")[0].classList.add('fa-sun-o');
  }
}
